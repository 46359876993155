<!-- =========================================================================================
  File Name: ECommerceShop.vue
  Description: eCommerce Shop Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <ais-instant-search
      :search-client="searchClient"
      index-name="instant_search"
      id="algolia-instant-search-demo"
    >
      <!-- AIS CONFIG -->
      <ais-configure :hits-per-page.camel="9" />

      <div class="algolia-header mb-4">
        <div class="flex md:items-end items-center justify-between flex-wrap">
          <!-- TOGGLE SIDEBAR BUTTON -->
          <feather-icon
            class="inline-flex lg:hidden cursor-pointer mr-4"
            icon="MenuIcon"
            @click.stop="toggleFilterSidebar"
          />

          <div class="flex justify-between items-end flex-grow">
            <!-- Stats -->
            <!--            <ais-stats>-->
            <!--              <p slot-scope="{ hitsPerPage, nbPages, nbHits, page, processingTimeMS, query }" class="font-semibold md:block hidden">-->
            <!--                {{ nbHits }} results found in {{ processingTimeMS }}ms-->
            <!--              </p>-->
            <!--            </ais-stats>-->

            <div class="flex flex-wrap">
              <!-- SORTING -->
              <!--              <ais-sort-by :items="[-->
              <!--                                { value: 'instant_search', label: 'Featured' },-->
              <!--                                { value: 'instant_search_price_asc', label: 'Lowest Price' },-->
              <!--                                { value: 'instant_search_price_desc', label: 'Highest Price' },-->
              <!--                            ]">-->
              <!--                <vs-select-->
              <!--                  :value="currentRefinement"-->
              <!--                  slot-scope="{ items, currentRefinement, refine }"-->
              <!--                  @input="(val) => refine(val)"-->
              <!--                  class="mr-4 vs-input-shadow-drop vs-select-no-border d-theme-input-dark-bg w-48">-->
              <!--                  <vs-select-item v-for="item in items" :key="item.value" :value="item.value" :text="item.label" />-->
              <!--                </vs-select>-->
              <!--              </ais-sort-by>-->

              <!-- ITEM VIEW - GRID/LIST -->
              <div>
                <feather-icon
                  icon="GridIcon"
                  @click="currentItemView = 'item-grid-view'"
                  class="p-2 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer"
                  :svgClasses="{
                    'text-primary stroke-current':
                      currentItemView == 'item-grid-view',
                  }"
                />
                <feather-icon
                  icon="ListIcon"
                  @click="currentItemView = 'item-list-view'"
                  class="p-2 ml-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer hidden sm:inline-flex"
                  :svgClasses="{
                    'text-primary stroke-current':
                      currentItemView == 'item-list-view',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="algolia-content-container" class="relative clearfix">
        <!-- RIGHT COL -->
        <div>
          <!-- SEARCH BAR -->
          <!--          <ais-search-box>-->
          <!--            <div slot-scope="{ currentRefinement, isSearchStalled, refine }">-->
          <!--              <div class="relative mb-8">-->

          <!--                &lt;!&ndash; SEARCH INPUT &ndash;&gt;-->
          <!--                <vs-input class="w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg" placeholder="Search here" v-model="currentRefinement" @input="refine($event)" @keyup.esc="refine('')" size="large" />-->

          <!--                &lt;!&ndash; SEARCH LOADING &ndash;&gt;-->
          <!--                <p :hidden="!isSearchStalled" class="mt-4 text-grey">-->
          <!--                  <feather-icon icon="ClockIcon" svgClasses="w-4 h-4" class="mr-2 align-middle" />-->
          <!--                  <span>Loading...</span>-->
          <!--                </p>-->

          <!--                &lt;!&ndash; SEARCH ICON &ndash;&gt;-->
          <!--                <div slot="submit-icon" class="absolute top-0 right-0 py-4 px-6" v-show="!currentRefinement">-->
          <!--                  <feather-icon icon="SearchIcon" svgClasses="h-6 w-6" />-->
          <!--                </div>-->

          <!--                &lt;!&ndash; CLEAR INPUT ICON &ndash;&gt;-->
          <!--                <div slot="reset-icon" class="absolute top-0 right-0 py-4 px-6" v-show="currentRefinement">-->
          <!--                  <feather-icon icon="XIcon" svgClasses="h-6 w-6 cursor-pointer" @click="refine('')" />-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </ais-search-box>-->
          <div class="vx-row mb-12 mt-8">
            <div class="vx-col md:w-full sm:w-full w-full">
              <div class="centerx mb-8">
                <span class="mr-6"> E Pharmacy Status : </span>
                <span class="mr-6">
                  <vs-radio
                    vs-name="x"
                    v-model="pharmacyStatus"
                    :vs-value="null"
                    >None</vs-radio
                  >
                </span>
                <span class="mr-6">
                  <vs-radio
                    vs-name="x"
                    v-model="pharmacyStatus"
                    :vs-value="true"
                    >Yes</vs-radio
                  >
                </span>
                <span class="mr-6">
                  <vs-radio
                    vs-name="x"
                    v-model="pharmacyStatus"
                    :vs-value="false"
                    >No</vs-radio
                  >
                </span>
                <span class="mr-6">
                  <vs-radio
                    vs-name="x"
                    v-model="pharmacyStatus"
                    :vs-value="'null'"
                    >N/A</vs-radio
                  >
                </span>
              </div>
              <vs-input
                type="search"
                @keyup.enter="loadProducts"
                v-model="searchTerm"
                placeholder="Search.."
                class="mb-4 md:mb-0 w-full"
              />
            </div>
            <!--            <div class="vx-col md:w-1/5 sm:w-1/2 w-full">-->
            <!--              <vs-button class="mr-4 w-full" @click="loadProducts"-->
            <!--                         color="success" type="border" icon-pack="feather" >SEARCH</vs-button>-->
            <!--            </div>-->
          </div>

          <!-- SEARCH RESULT -->
          <ais-hits>
            <div slot-scope="{ items }">
              <!-- GRID VIEW -->
              <template v-if="currentItemView == 'item-grid-view'">
                <div class="items-grid-view vx-row match-height">
                  <div
                    class="vx-col md:w-1/4 xl:w-1/6 sm:w-1/2 w-full"
                    v-for="item in products"
                    :key="item.Id"
                  >
                    <item-grid-view :item="item">
                      <!-- SLOT: ACTION BUTTONS -->
                      <template slot="action-buttons">
                        <div class="flex flex-wrap">
                          <!-- PRIMARY BUTTON: ADD TO WISH LIST -->
                          <div
                            @click="onClickProductUpdate(item.Id)"
                            class="item-view-primary-action-btn p-3 flex flex-grow items-center justify-center cursor-pointer"
                          >
                            <feather-icon icon="EditIcon" />

                            <span class="text-sm font-semibold ml-2">EDIT</span>
                          </div>

                          <!-- SECONDARY BUTTON: ADD-TO/VIEW-IN CART -->
                          <div
                            class="item-view-secondary-action-btn bg-primary p-3 flex flex-grow items-center justify-center text-white cursor-pointer"
                            @click="onClickProductDetails(item.Id)"
                          >
                            <feather-icon icon="EyeIcon" svgClasses="h-4 w-4" />

                            <span class="text-sm font-semibold ml-2">VIEW</span>
                          </div>
                        </div>
                      </template>
                    </item-grid-view>
                  </div>
                </div>
              </template>

              <!-- LIST VIEW -->
              <template v-else>
                <div
                  class="items-list-view mb-base"
                  v-for="item in products"
                  :key="item.objectID"
                >
                  <item-list-view :item="item">
                    <!-- SLOT: ACTION BUTTONS -->
                    <template slot="action-buttons">
                      <div
                        @click="onClickProductUpdate(item.Id)"
                        class="item-view-primary-action-btn p-3 rounded-lg flex flex-grow items-center justify-center cursor-pointer mb-3"
                      >
                        <feather-icon
                          icon="HeartIcon"
                          svgClasses="[{'text-danger fill-current' : 'h-4 w-4']"
                        />
                        <span class="text-sm font-semibold ml-2">EDIT</span>
                      </div>
                      <div
                        class="item-view-secondary-action-btn bg-primary p-3 rounded-lg flex flex-grow items-center justify-center text-white cursor-pointer"
                        @click="onClickProductDetails(item.Id)"
                      >
                        <feather-icon
                          icon="ShoppingBagIcon"
                          svgClasses="h-4 w-4"
                        />
                        <span class="text-sm font-semibold ml-2">VIEW</span>
                      </div>
                    </template>
                  </item-list-view>
                </div>
              </template>
            </div>
          </ais-hits>

          <!-- PAGINATION -->
          <!--<ais-pagination>
            <div slot-scope="{
                                currentRefinement,
                                nbPages,
                                pages,
                                isFirstPage,
                                isLastPage,
                                refine,
                                createURL
                            }"
            >

              <vs-pagination

                :total="nbPages"
                :max="7"
                :value="currentRefinement + 1"
                @input="(val) => { refine(val - 1) }" />
            </div>
          </ais-pagination>-->
        </div>
      </div>
    </ais-instant-search>
    <product-update-pop-up ref="productUpdatePopUP" />
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const ProductRepository = RepositoryFactory.get('product');
import ProductUpdatePopUp from '@/components/popups/products/ProductUpdatePopUp';

import {
  AisClearRefinements,
  AisConfigure,
  AisHierarchicalMenu,
  AisHits,
  AisInstantSearch,
  AisNumericMenu,
  AisPagination,
  AisRangeInput,
  AisRatingMenu,
  AisRefinementList,
  AisSearchBox,
  AisSortBy,
  AisStats,
} from 'vue-instantsearch';
import algoliasearch from 'algoliasearch/lite';

export default {
  components: {
    ItemGridView: () => import('./components/ItemGridView.vue'),
    ItemListView: () => import('./components/ItemListView.vue'),
    AisClearRefinements,
    AisConfigure,
    AisHierarchicalMenu,
    AisHits,
    AisInstantSearch,
    AisNumericMenu,
    AisPagination,
    AisRangeInput,
    AisRatingMenu,
    AisRefinementList,
    AisSearchBox,
    AisSortBy,
    AisStats,
    ProductUpdatePopUp,
  },
  data() {
    return {
      searchClient: algoliasearch(
        'latency',
        '6be0576ff61c053d5f9a3225e2a90f76',
      ),
      products: [],
      searchEnteredTerm: '',
      // Filter Sidebar
      isFilterSidebarActive: true,
      clickNotClose: true,
      currentItemView: 'item-grid-view',
      pharmacyStatus: null,
      numericItems: [
        { label: 'All' },
        { label: '<= $10', end: 10 },
        { label: '$10 - $100', start: 10, end: 100 },
        { label: '$100 - $500', start: 100, end: 500 },
        { label: '>= $500', start: 500 },
      ],
      algoliaCategories: [
        'hierarchicalCategories.lvl0',
        'hierarchicalCategories.lvl1',
        'hierarchicalCategories.lvl2',
        'hierarchicalCategories.lvl3',
      ],
    };
  },
  computed: {
    toValue() {
      return (value, range) => [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ];
    },

    // GRID VIEW
    isInCart() {
      return (itemId) => this.$store.getters['eCommerce/isInCart'](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters['eCommerce/isInWishList'](itemId);
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    searchTerm: {
      get() {
        return this.searchEnteredTerm;
      },
      set(v) {
        this.searchEnteredTerm = v.trim();
      },
    },
  },
  watch: {
    windowWidth() {
      this.setSidebarWidth();
    },
  },
  methods: {
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false;
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true;
      }
    },

    // GRID VIEW - ACTIONS
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    },
    toggleItemInWishList(item) {
      this.$store.dispatch('eCommerce/toggleItemInWishList', item);
    },
    additemInCart(item) {
      this.$store.dispatch('eCommerce/additemInCart', item);
    },
    cartButtonClicked(item) {
      this.isInCart(item.objectID)
        ? this.$router.push('/apps/eCommerce/checkout').catch(() => {})
        : this.additemInCart(item);
    },
    onClickProductDetails(id) {
      this.$router.push({ path: `/products/${id}` });
    },
    async onClickProductUpdate(id) {
      await this.$refs.productUpdatePopUP.openPopup(id);
    },
    async dataRefresh() {
      await this.loadProducts();
    },
    async loadProducts(isInitialSearch) {
      if (!this.searchTerm && !isInitialSearch) {
        return;
      }
      this.$vs.loading();

      this.products = (
        await ProductRepository.search(this.searchTerm || ' ', {
          pharmacyStatus: this.pharmacyStatus,
        })
      ).data;

      this.$vs.loading.close();
    },
  },
  async created() {
    this.setSidebarWidth();
    // await this.loadProducts(true); // enable if initial request is needed

    // this.$vs.loading()
    // this.products =  (await ProductRepository.search(this.searchTerm)).data
    // this.$vs.loading.close()
  },
};
</script>


<style lang="scss">
#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #algolia-content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}
</style>

